import request from '@utils/request'

// 登陆接口
export const login = data => {
  return request({
    url: '/api/v1/supervise/login',
    method: 'POST',
    data
  })
}
// 市场排名
export const getMarketRanking = () => {
  return request({
    url: '/api/v1/visual/getMarketRanking'
  })
}
// 营业执照、许可证、健康证信息
export const getCertificate = () => {
  return request({
    url: '/api/v1/supervise/certificate'
  })
}
// 市场商品类目热销笔数比例（圆环）
export const getCategorySellProportion = type => {
  return request({
    url: '/api/v1/supervise/categorySellProportion?type=' + type
  })
}
// 市场快检统计（饼图）
export const getDetectionInformation = id => {
  return request({
    url: '/api/v1/supervise/detectionInformation?type=' + id
  })
}
// 市场快检统计 （柱状图）
export const detectionBar = params => {
  return request({
    url: '/api/v1/supervise/detectionBar',
    params
  })
}
// 地图经纬点
export const getMap = () => {
  return request({
    url: '/api/v1/supervise/getMap'
  })
}
// 地图统计
export const getTotal = () => {
  return request({
    url: '/api/v1/supervise/getTotal'
  })
}
// 市场配套设施
export const supportingFacility = () => {
  return request({
    url: '/api/v1/supervise/supportingFacility'
  })
}
// 进货台账
export const parameter = data => {
  return request({
    url: '/api/v1/supervise/parameter',
    params: data
  })
}
// 消费投诉统计
export const complaint = params => {
  return request({
    url: '/api/v1/supervise/complaint',
    params
  })
}
// 24小时交易数据
export const trading = () => {
  return request({
    url: '/api/v1/supervise/trading'
  })
}

// about页面
// 头部六个块
export const getAboutInfoBox = params => {
  return request({
    url: '/api/v1/visual/homeInfoBox',
    params
  })
}
// 热销商品销售分析旁行
export const getProductAnalysis = params => {
  return request({
    url: '/api/v1/visual/productAnalysis',
    params
  })
}
// 市场当天交易走势
export const getDayTradingTrend = params => {
  return request({
    url: 'api/v1/visual/dayTradingTrend',
    params
  })
}
// 实时交易数据 热销商品销售分析旁行
export const getTradingLog = params => {
  return request({
    url: '/api/v1/visual/tradingLog',
    params
  })
}
// 实时交易数据 热销商品销售分析旁行
export const getRanking = params => {
  return request({
    url: '/api/v1/visual/ranking',
    params
  })
}
// 实时交易数据 热销商品销售分析旁行
export const getDetectionIn = params => {
  return request({
    url: '/api/v1/visual/detectionInformation',
    params
  })
}
// 合格
export const getDetectionPassCount = params => {
  return request({
    url: '/api/v1/visual/detectionPassCount',
    params
  })
}
// 合格
export const getPersonnel = params => {
  return request({
    url: '/api/v1/visual/personnel',
    params
  })
}
// 合格
export const getMarketPlan = params => {
  return request({
    url: '/api/v1/supervise/marketPlan',
    params
  })
}
// 监控
export const getCamera = params => {
  return request({
    url: '/api/v1/supervise/getCamera',
    params
  })
}
// 各个市场的交易走势折线图
export const monthTradingTrend = () => {
  return request({
    url: '/api/v1/supervise/monthTradingTrend'
  })
}

// 食品快检公示的5个块
export const infoBox = id => {
  return request({
    url: '/api/v1/visual/infoBox?market_id=' + id
  })
}
// 年初至和当前值
export const getDetectionBar = params => {
  return request({
    url: '/api/v1/visual/detectionBar',
    params
  })
}
// 检测类目饼状图
export const detectionProject = params => {
  return request({
    url: '/api/v1/visual/detectionProject',
    params
  })
}
// 检测信息
export const detectionInformation = params => {
  return request({
    url: '/api/v1/visual/detectionInformation',
    params
  })
}
// 获取市场名称
export const getMarket = params => {
  return request({
    url: '/api/v1/supervise/getMarket',
    params
  })
}
// 获取市场设备设施名称
export const getServiceList = () => {
  return request({
    url: '/api/v1/supervise/getServiceList'
  })
}
// 公厕的详细信息
export const publicToilets = id => {
  return request({
    url: '/api/v1/supervise/publicToilets?mk_id=' + id
  })
}
// 其他服务设备（除了公厕）
export const serviceFacilitys = id => {
  return request({
    url: '/api/v1/supervise/serviceFacilitys?type=' + id
  })
}

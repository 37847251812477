<template>
  <div class="homeBorder">
    <dv-border-box-13>
      <div class="content flex" style="align-items: normal">
        <div class="homeBorderTitle flex" v-if="name">
          <div class="flex">
            {{ name }}
            <span v-if="name === '消费统计投诉统计'" class="complaintTotal">{{
              complaintTotal
            }}</span>
          </div>
          <div
            class="year"
            v-if="name !== '市场配套设施' && name !== '市场排名'"
          >
            <span :class="{ span: type === 'day' }" @click="dayMonthYear('day')"
              >日</span
            >
            <span
              :class="{ span: type === 'month' }"
              @click="dayMonthYear('month')"
              >月</span
            >
            <span
              :class="{ span: type === 'year' }"
              @click="dayMonthYear('year')"
              >年</span
            >
          </div>
        </div>
        <div class="slot" :style="{ height: !name ? '100%' : '' }">
          <slot></slot>
        </div>
      </div>
    </dv-border-box-13>
  </div>
</template>

<script>
export default {
  name: 'homeBorder',
  props: ['name'],
  data () {
    return {
      type: 'day',
      complaintTotal: 0
    }
  },
  created () {},
  inject: ['button'],
  methods: {
    dayMonthYear (type) {
      this.button(type)
      if (this.type === type) return
      if (this.name === '市场快检统计') {
        this.$children[0].$children[0].loading = true
        this.$children[0].$children[0].$children[0].dayMonthYear(type)
        this.$children[0].$children[0].$children[1].dayMonthYear(type)
      } else {
        this.$children[0].$children[0].dayMonthYear(type)
      }
      this.type = type
    }
  },
  mounted () {}
}
</script>
<style lang="less">
.homeBorder {
  width: 100%;
  height: 100%;
  @font-face {
    font-family: DIGIT;
    src: url('../../assets/DS-DIGIT.TTF');
  }
  .content {
    width: 100%;
    height: 100%;
    padding: 0.1rem 0.08rem;
    box-sizing: border-box;
    flex-direction: column;
  }
  .complaintTotal {
    font-family: DIGIT;
    font-size: 0.3rem;
    margin-left: 0.15rem;
  }
  .homeBorderTitle {
    font-size: 0.2rem;
    color: #00fdfa;
    padding: 0.04rem 0.2rem 0 0.1rem;
    box-sizing: border-box;
    .year {
      span {
        color: #ffff;
        border: 1px solid #ffff;
        padding: 0 0.1rem;
        box-sizing: border-box;
        font-size: 0.15rem;
        border-radius: 0.04rem;
        cursor: pointer;
      }
      span:nth-child(2) {
        margin: 0 0.1rem;
      }
      .span {
        color: #00fdfa;
        border: 1px solid #00fdfa;
      }
    }
  }
  .slot {
    width: 100%;
    height: calc(100% - 0.3rem);
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: { bol: true },
  },
  {
    path: "/Echartsline",
    name: "Echartsline",
    component: () =>
      import(/* webpackChunkName: "echartsline" */ "../views/Echartsline.vue"),
    meta: { bol: true },
  },
  {
    path: "/Detection",
    name: "Detection",
    component: () =>
      import(/* webpackChunkName: "Detection" */ "../views/Detection.vue"),
    meta: { bol: true },
  },
  {
    path: "/Classification",
    name: "Classification",
    component: () =>
      import(/* webpackChunkName: "Detection" */ "../views/Classification.vue"),
    meta: { bol: true },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  // for (const p in window._cancelToken) {
  //   //当前请求在数组中存在时执行函数体
  //   window._cancelToken[p].f() //执行取消操作
  //   // 全局添加取消的数组方便路由跳转时取消剩余的请求
  //   window._cancelToken = []
  // }
  if (!localStorage.getItem("USERINFO") && to.path !== "/Login") {
    next("/Login");
  } else {
    next();
  }
});

export default router;

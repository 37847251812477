import Vue from 'vue'
import Vuex from 'vuex'
import { login } from '@api'

Vue.use(Vuex)

const atobs = () => {
  const Info = localStorage.getItem('USERINFO')
  if (Info) {
    // 解密
    return JSON.parse(decodeURIComponent(atob(Info)))
  } else {
    return false
  }
}

export default new Vuex.Store({
  namespaced: true,
  state: {
    userInfo: atobs() || {},
    pending: [] // 声明一个数组用于存储每个ajax请求的取消函数和ajax标识
  },
  mutations: {
    USER_INFO (state, userInfo) {
      state.userInfo = userInfo
      // 加密
      const base = btoa(encodeURIComponent(JSON.stringify(userInfo)))
      localStorage.setItem('USERINFO', base)
    },
    CLEAR_TOKEN ({ userInfo }) {
      userInfo = ''
      localStorage.removeItem('USERINFO')
    }
  },
  actions: {
    getUserInfo ({ commit }, data) {
      return login(data).then(res => {
        commit('USER_INFO', res.data)
      })
    }
  },
  modules: {}
})

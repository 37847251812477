import Vue from 'vue'
import {
  fullScreenContainer,
  borderBox13,
  scrollBoard,
  borderBox10,
  borderBox12,
  borderBox8
} from '@jiaminghi/data-view'

import {
  Input,
  Icon,
  Form,
  FormItem,
  Message,
  Loading,
  Image,
  Rate
} from 'element-ui'
import VueCarousel from 'vue-carousel'

Vue.use(Input)
Vue.use(Icon)
Vue.use(Form)
Vue.use(Image)
Vue.use(FormItem)
Vue.use(Rate)

Vue.use(Loading.directive)
Vue.prototype.$message = Message

Vue.use(fullScreenContainer)
Vue.use(borderBox13)
Vue.use(borderBox10)
Vue.use(borderBox8)
Vue.use(borderBox12)
Vue.use(scrollBoard)

Vue.use(VueCarousel)

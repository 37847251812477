<template>
  <div
    class="pies flex"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div
      class="pieEchart"
      ref="pieEchart"
      :style="{ width: type === 'home' ? '50%' : '100%' }"
    ></div>
    <div class="pieEchart flex direction" v-if="type === 'home'">
      <div class="list flex" v-for="(item, i) in arr1" :key="item.name">
        <div :class="['name', 'name' + i]">{{ item.name }}</div>
        <div>{{ item.count }}%</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCategorySellProportion,
  getDetectionInformation,
  detectionProject,
} from "@api/index";
export default {
  name: "pies",
  props: ["type", "loadings"],
  data() {
    return {
      arr1: [],
      loading: false,
    };
  },
  methods: {
    getParametricEquation(
      startRatio,
      endRatio,
      isSelected,
      isHovered,
      knum,
      h
    ) {
      // 计算
      const midRatio = (startRatio + endRatio) / 2;
      const startRadian = startRatio * Math.PI * 2;
      const endRadian = endRatio * Math.PI * 2;
      const midRadian = midRatio * Math.PI * 2;
      // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
      const k = typeof knum !== "undefined" ? knum : 1 / 3;
      // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
      const offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
      const offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;
      // 计算高亮效果的放大比例（未高亮，则比例为 1）
      const hoverRate = isHovered ? 1.05 : 1;
      // 返回曲面参数方程
      return {
        u: {
          min: -Math.PI,
          max: Math.PI * 3,
          step: Math.PI / 32,
        },
        v: {
          min: 0,
          max: Math.PI * 2,
          step: Math.PI / 20,
        },
        x(u, v) {
          if (u < startRadian) {
            return (
              offsetX +
              Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          if (u > endRadian) {
            return (
              offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
        },
        y(u, v) {
          if (u < startRadian) {
            return (
              offsetY +
              Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          if (u > endRadian) {
            return (
              offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
        },
        z(u, v) {
          if (u < -Math.PI * 0.5) {
            return Math.sin(u);
          }
          if (u > Math.PI * 2.5) {
            return Math.sin(u) * h * 0.1;
          }
          return Math.sin(v) > 0 ? 1 * h * 0.1 : -1;
        },
      };
    },
    getPie3D(pieData, internalDiameterRatio, boxHeight) {
      let series = [];
      let sumValue = 0;
      let startValue = 0;
      let endValue = 0;
      const legendData = [];
      const k =
        typeof internalDiameterRatio !== "undefined"
          ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio)
          : 1 / 3;
      // 为每一个饼图数据，生成一个 series-surface 配置
      for (let i = 0; i < pieData.length; i++) {
        sumValue += pieData[i].value;
        const seriesItem = {
          name:
            typeof pieData[i].name === "undefined"
              ? `series${i}`
              : pieData[i].name,
          type: "surface",
          parametric: true,
          wireframe: {
            show: false,
          },
          pieData: pieData[i],
          pieStatus: {
            selected: false,
            hovered: false,
            k,
          },
        };
        if (typeof pieData[i].itemStyle !== "undefined") {
          const itemStyle = {
            color: "",
            opacity: "",
          };
          itemStyle.color =
            typeof pieData[i].itemStyle.color !== "undefined"
              ? (itemStyle.color = pieData[i].itemStyle.color)
              : null;
          itemStyle.opacity =
            typeof pieData[i].itemStyle.opacity !== "undefined"
              ? (itemStyle.opacity = pieData[i].itemStyle.opacity)
              : null;

          seriesItem.itemStyle = itemStyle;
        }
        series.push(seriesItem);
      }
      // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
      // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
      for (let i = 0; i < series.length; i++) {
        endValue = startValue + series[i].pieData.value;
        series[i].pieData.startRatio = startValue / sumValue;
        series[i].pieData.endRatio = endValue / sumValue;
        series[i].parametricEquation = this.getParametricEquation(
          series[i].pieData.startRatio,
          series[i].pieData.endRatio,
          false,
          false,
          k,
          series[i].pieData.value
        );
        startValue = endValue;
        legendData.push(series[i].name);
      }
      // 补充一个透明的圆环，用于支撑高亮功能的近似实现。
      series.push({
        name: "mouseoutSeries",
        type: "surface",
        parametric: true,
        wireframe: {
          show: false,
        },
        itemStyle: {
          opacity: 0,
        },
        parametricEquation: {
          u: {
            min: 0,
            max: Math.PI * 2,
            step: Math.PI / 20,
          },
          v: {
            min: 0,
            max: Math.PI,
            step: Math.PI / 20,
          },
          x(u, v) {
            return Math.sin(v) * Math.sin(u) + Math.sin(u);
          },
          y(u, v) {
            return Math.sin(v) * Math.cos(u) + Math.cos(u);
          },
          z(u, v) {
            return Math.cos(v) > 0 ? 0.1 : -0.1;
          },
        },
      });

      // 准备待返回的配置项，把准备好的 legendData、series 传入。
      const option = {
        tooltip: {
          textStyle: {
            fontSize: 13,
          },
          formatter: (params) => {
            if (
              params.seriesName !== "mouseoutSeries" &&
              params.seriesName !== "pie2d"
            ) {
              return (
                `${params.seriesName}<br/>` +
                `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>` +
                `${option.series[params.seriesIndex].pieData.value}%`
              );
            }
          },
        },
        animation: true,
        xAxis3D: {
          min: -1,
          max: 1,
        },
        yAxis3D: {
          min: -1,
          max: 1,
        },
        zAxis3D: {
          min: -1,
          max: "dataMax",
        },
        grid3D: {
          top: -15,
          show: false,
          boxHeight,
          viewControl: {
            alpha: 35,
            beta: -5,
            distance: this.type === "home" ? 150 : 250, // 修改到眼睛的视距直观大小
            rotateSensitivity: 0,
            zoomSensitivity: 0,
            panSensitivity: 0,
            autoRotate: this.type === "home",
          },
          // 后处理特效可以为画面添加高光、景深、环境光遮蔽（SSAO）、调色等效果。可以让整个画面更富有质感。
          postEffect: {
            enable: true,
            bloom: {
              enable: true,
              bloomIntensity: 0.1,
            },
            SSAO: {
              enable: true,
              quality: "medium",
              radius: 2,
            },
          },
          temporalSuperSampling: {
            enable: true,
          },
        },
        series,
      };
      if (this.type === "chunk") {
        pieData.map((item) => {
          item.itemStyle.color = "transparent";
        });
        series.push({
          name: "pie2d",
          type: "pie",
          labelLine: {
            length: 20,
            length2: 70,
            lineStyle: {
              color: "#00eaff",
            },
          },
          startAngle: 10, //起始角度，支持范围[0, 360]。
          clockwise: false, //饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
          radius: "80%",
          center: ["50%", "50%"],
          data: pieData,
          itemStyle: {
            opacity: 1,
          },
        });
        option.labelLine = {
          show: true,
        };
        option.label = {
          show: true,
          position: "outside",
          rich: {
            b: {
              color: "#fff",
            },
            c: {
              color: "#fff",
            },
          },
          formatter: "{b|{b}}\n{c|{c}%}",
        };
      }

      return option;
    },
    pie() {
      const echartData = this.arr1;
      const colorArr = [
        "#d72ef6",
        "#8045f8",
        "#69cde7",
        "#2748f7",
        "#edbc3b",
        "#9083FD",
      ];
      const newEchartData = echartData.map((item, index) => {
        return {
          name: item.name,
          value: item.count,
          itemStyle: {
            color: colorArr[index],
          },
        };
      });
      const options = this.getPie3D(
        newEchartData,
        this.type === "home" ? 0.7 : 0,
        this.type === "home" ? 30 : 45
      );
      this.myEcharts.setOption(options, true);
      this.myEcharts.resize();
      this.loading = false;
    },
    getCategorySellProportion(type = 1) {
      this.loading = true;
      getCategorySellProportion(type).then((res) => {
        let arr = [];
        res.data.forEach((item) => {
          arr.push({
            name: item.name,
            count: item.radio * 1,
          });
        });
        this.arr1 = arr;
        this.pie();
      });
    },
    getDetectionInformation(type = "day") {
      getDetectionInformation(type).then((res) => {
        let arr = [];
        this.$parent.titel = {
          detection_total_count: res.data.detection_total_count,
          detection_pass_count: res.data.detection_pass_count,
          detection_pass_rate: res.data.detection_pass_rate,
        };
        res.data.list.forEach((item) => {
          arr.push({
            name: item.name,
            count: item.y * 1,
          });
        });
        this.arr1 = arr;
        this.pie();
      });
    },
    dayMonthYear(type) {
      switch (type) {
        case "day":
          this.type === "home"
            ? this.getCategorySellProportion(1)
            : this.getDetectionInformation(type);
          break;
        case "month":
          this.type === "home"
            ? this.getCategorySellProportion(2)
            : this.getDetectionInformation(type);
          break;
        case "year":
          this.type === "home"
            ? this.getCategorySellProportion(3)
            : this.getDetectionInformation(type);
          break;
      }
    },
    detectionProject(params) {
      detectionProject(params).then((res) => {
        let arr = [];
        res.data.forEach((item) => {
          arr.push({
            name: item.name,
            count: item.y * 100,
          });
        });
        this.arr1 = arr;
        this.pie();
        this.loadings();
      });
    },
  },
  mounted() {
    this.myEcharts = this.$echarts.init(this.$refs.pieEchart);
    if (this.type === "home") {
      this.getCategorySellProportion();
    }
    if (this.type === "chunk") {
      this.getDetectionInformation();
    }
  },
};
</script>
<style lang="less" scoped>
.pies {
  width: 100%;
  height: 100%;
  .pieEchart {
    width: 50%;
    height: 100%;
    // border: 1px solid red;
  }
  .direction {
    flex-direction: column;
    padding: 0.1rem 0;
    box-sizing: border-box;
  }
  .list {
    width: 60%;
    font-size: 0.16rem;
    color: #fff;
    .name {
      position: relative;
    }
    .name::before {
      content: " ";
      display: block;
      position: absolute;
      left: -0.2rem;
      top: calc(50% - 0.06rem);
      width: 0.12rem;
      height: 0.12rem;
      border-radius: 50%;
    }

    .name0::before {
      background: #d72ef6;
    }
    .name1::before {
      background: #8045f8;
    }
    .name2::before {
      background: #69cde7;
    }
    .name3::before {
      background: #2748f7;
    }
    .name4::before {
      background: #edbc3b;
    }
    .name5::before {
      background: #9083fd;
    }
  }
}
</style>

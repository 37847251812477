<template>
  <div
    class="chunk"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="inspect">
      <div class="titel">
        <div>
          <div>检测批次</div>
          <div>3058次</div>
        </div>
        <div>
          <div>检测批次</div>
          <div>3058次</div>
        </div>
        <div>
          <div>检测批次</div>
          <div>3058次</div>
        </div>
      </div>
      <div class="pie" @click="$router.push('/Detection')">
        <pie type="chunk" />
      </div>
    </div>
    <div class="inspect border">
      <bar :type="2" />
    </div>
  </div>
</template>

<script>
import pie from "./pie.vue";
import bar from "./bar.vue";
export default {
  name: "chunk",
  components: {
    pie,
    bar,
  },
  data() {
    return {
      titel: {},
      loading: false,
    };
  },
  created() {},
  methods: {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.chunk {
  width: 100%;
  height: 100%;
  .inspect {
    width: 100%;
    height: 50%;
    .titel {
      font-size: 0.18rem;
      color: #00fdfa;
      padding: 4px 20px 0 10px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .border {
    border-top: 1px solid #033bf47a;
  }
  .pie {
    width: 100%;
    height: calc(100% - 0.5rem);
  }
}
</style>

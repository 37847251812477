<template>
  <dv-full-screen-container>
    <div class="app">
      <div class="apptitle" v-if="$route.name !== 'Login'">
        <div
          class="el-icon-s-home appName"
          @click="$router.go(-1)"
          v-if="$route.meta.bol"
        >
          首页
        </div>
        <div class="appName appNames" v-if="$route.query.tilte">
          {{ $route.query.tilte }}
        </div>
        大鹏新区智慧农贸监管可视化大数据管理平台
      </div>
      <router-view></router-view>
    </div>
  </dv-full-screen-container>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {}
  },
  created () {
    this.width()
    window.onresize = this.width
  },
  methods: {
    width () {
      const rootFontSize = (document.documentElement.clientWidth * 100) / 1920
      document.documentElement.style.fontSize = rootFontSize + 'px'
    }
  }
}
</script>

<style lang="less">
.message {
  font-size: 0.16rem;
}
.el-loading-spinner {
  font-size: 0.4rem;
  margin: 0;
  transform: translateY(-50%);
}
html,
body,
.app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.vjs-control-text,
.vjs-modal-dialog-description,
.vjs-control-text {
  display: none;
}
.app {
  background: #081941;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.apptitle {
  font-size: 00.28rem;
  color: #00eaff;
  width: 100%;
  height: 0.7rem;
  line-height: 0.8rem;
  background-image: url('./assets/top.png');
  background-position: center;
  background-size: 100% 80%;
  background-repeat: no-repeat;
  text-align: center;
  user-select: none;
  background-position-y: bottom;
  position: relative;
}
.appName {
  position: absolute;
  top: 00.3rem;
  color: #fff;
  font-size: 00.24rem;
  left: 00.3rem;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.appNames {
  left: auto;
  line-height: 1;
  right: 00.3rem;
}
.el-icon-star-on {
  color: yellow;
  font-size: 0.2rem;
}
.el-icon-star-off {
  font-size: 0.199rem;
}
.justifyCenter {
  justify-content: center;
}
</style>

<template>
  <div
    class="lines"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="titel flex" v-if="type === 'home'">
      <div>
        <div class="item">市场本月交易{{ status === 1 ? "量" : "额" }}</div>
        <div>
          {{
            status === 1
              ? trading.monthOrderCount + "笔"
              : trading.monthOrderPrice + "元"
          }}
        </div>
      </div>
      <div>
        <div class="item">市场本月交易{{ status === 1 ? "量" : "额" }}</div>
        <div>
          {{
            status === 1
              ? trading.todayOrderCount + "笔"
              : trading.todayOrderPrice + "元"
          }}
        </div>
      </div>
      <div>
        <div class="item">市场本月交易{{ status === 1 ? "量" : "额" }}</div>
        <div>
          {{
            status === 1
              ? trading.yearOrderCount + "笔"
              : trading.yearOrderPrice + "元"
          }}
        </div>
      </div>
      <div :class="{ btn: true, btns: status === 1 }" @click="status = 1">
        交易量
      </div>
      <div :class="{ btn: true, btns: status === 2 }" @click="status = 2">
        交易额
      </div>
    </div>
    <div
      class="linesEchart"
      :style="{ height: type === 'about' ? '100%' : '80%' }"
      ref="linesEchart"
      @click="Echartsline"
    ></div>
  </div>
</template>

<script>
import { trading, getDayTradingTrend } from "@api/index";

export default {
  name: "lines",
  props: ["type"],
  data() {
    this.option2 = {
      // x轴
      xAxis: {
        data: [],
        boundaryGap: false, // 坐标轴右侧是否留白, 默认是true
        axisLabel: {
          textStyle: {
            color: "#ffffff",
            fontSize: "12",
          },
        },
        axisTick: {
          show: false,
        },
      },
      // 坐标系内绘图网格
      grid: {
        left: "2%",
        right: "2%",
        bottom: "10%",
        top: "3%",
        containLabel: true,
      },
      // 提示
      tooltip: {
        trigger: "axis", // 坐标轴触发
        axisPointer: {
          // 坐标轴指示器配置项
          type: "cross", //  十字准星指示器。表示启用两个正交的轴的 axisPointer
        },
      },
      // y轴
      yAxis: {
        axisLabel: {
          textStyle: {
            color: "#ffffff",
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: ["#0e2edc94"],
            width: 1,
            type: "solid",
          },
        },
      },
      series: [
        {
          name: "交易量",
          type: "line",
          smooth: true,
          symbol: "none",
          areaStyle: {
            normal: {
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "rgba(50, 169, 254, 0.7)",
                  },
                  {
                    offset: 0.5,
                    color: "rgba(50, 169, 254, 0.3)",
                  },
                  {
                    offset: 1,
                    color: "rgba(50, 169, 254, 0.1)",
                  },
                ],
                false
              ),
              shadowColor: "rgba(0, 0, 0, 0.1)",
              shadowBlur: 10,
            },
          },
          itemStyle: {
            normal: {
              color: "rgb(50, 169, 254)",
            },
          },
          data: [],
        },
        {
          name: "交易额",
          type: "line",
          smooth: true,
          symbol: "none",
          areaStyle: {
            normal: {
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "rgba(254, 147, 35, 0.7)",
                  },
                  {
                    offset: 0.5,
                    color: "rgba(254, 147, 35, 0.3)",
                  },
                  {
                    offset: 1,
                    color: "rgba(254, 147, 35, 0.1)",
                  },
                ],
                false
              ),
              shadowColor: "rgba(0, 0, 0, 0.1)",
              shadowBlur: 10,
            },
          },
          itemStyle: {
            normal: {
              color: "rgba(254, 147, 35)",
            },
          },
          data: [],
        },
      ],
    };
    return {
      status: 1,
      trading: {},
      loading: false,
      xAxis: ["周一", "周二", "周三", "周四", "周五"],
      series1: [10, 22, 29, 44, 49],
      series2: [18, 14, 35, 38, 55],
    };
  },
  created() {},
  methods: {
    echarts() {
      const MyEcharts2 = this.$echarts.init(this.$refs.linesEchart);
      const option2 = {
        ...this.option2,
      };
      if (this.type === "home") {
        option2.title = {
          text: "24交易走势（交易量/交易额）",
          bottom: "0%",
          left: "25%",
          textStyle: {
            //文字样式
            color: "#00FDFA",
            fontSize: "16",
          },
        };
        option2.legend = {
          icon: "rect", //形状  类型包括 circle，rect,line，roundRect，triangle，diamond，pin，arrow，none
          itemWidth: 16, // 设置宽度
          itemHeight: 16, // 设置高度
          itemGap: 20, // 设置间距
          data: ["交易量", "交易额"],
          bottom: "0%",
          left: "60%",
          textStyle: {
            //文字样式
            color: "#ffffff",
            fontSize: "16",
          },
          selectedMode: false,
        };
      }
      if (this.type === "about") {
        option2.grid.bottom = "2%";
      }
      option2.xAxis.data = this.xAxis;
      option2.series[0].data = this.series1;
      option2.series[1].data = this.series2;
      MyEcharts2.setOption(option2, true);
      MyEcharts2.resize();
    },
    tradings() {
      this.loading = true;
      trading().then((res) => {
        const xAxis = [];
        const series1 = [];
        const series2 = [];
        res.data.dateArr.forEach((item) => {
          xAxis.push(`${item.hour}时`);
          series1.push(item.count);
          series2.push(item.price);
        });
        this.xAxis = xAxis;
        this.series1 = series1;
        this.series2 = series2;
        delete res.data.dateArr;
        this.trading = res.data;
        this.echarts();
        this.loading = false;
      });
    },
    getDayTradingTrend() {
      getDayTradingTrend({ market_id: this.$route.query.id }).then((res) => {
        const xAxis = [];
        const series1 = [];
        const series2 = [];
        res.data.forEach((item) => {
          xAxis.push(item.hour + "时");
          series1.push(item.count);
          series2.push(`${item.price}`.replace(",", "") * 1);
        });
        this.xAxis = xAxis;
        this.series1 = series1;
        this.series2 = series2;
        this.echarts();
      });
    },
    Echartsline() {
      if (this.type === "home") {
        this.$router.push("/Echartsline");
      }
    },
  },
  mounted() {
    if (this.type === "home") {
      this.tradings();
    }
    if (this.type === "about") {
      this.getDayTradingTrend();
    }
  },
};
</script>
<style lang="less" scoped>
.lines {
  width: 100%;
  height: 100%;
  .titel {
    width: 100%;
    height: 20%;
    // border: 1px solid red;
    font-size: 0.2rem;
    color: #fff;
    padding: 0 0.1rem;
    box-sizing: border-box;
    .btn {
      padding: 0 0.1rem;
      box-sizing: border-box;
      color: #fff;
      border-radius: 0.04rem;
      border: 1px solid #fff;
      cursor: pointer;
    }
    .btns {
      border: 1px solid #00fdfa;
      color: #00fdfa;
    }
    .item {
      color: #00fdfa;
    }
  }
  .linesEchart {
    width: 100%;
    height: 80%;
  }
}
</style>

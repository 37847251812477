<template>
  <div class="rankings">
    <div class="top">
      <dv-scroll-board :config="config" />
    </div>
    <div class="bottom flex">
      <div class="bottomNum">
        <div class="name">营业执照</div>
        <div class="num">{{ Certificate.business_license_count }}份</div>
      </div>
      <div class="bottomNum">
        <div class="name">食品经营许可证</div>
        <div class="num">{{ Certificate.permit_count }}份</div>
      </div>
      <div class="bottomNum">
        <div class="name">健康证</div>
        <div class="num">{{ Certificate.health_certificate_count }}份</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMarketRanking, getCertificate } from '@api/index'
export default {
  name: 'rankings',
  data () {
    return {
      config: {
        header: ['排名', '市场名称', '分数'],
        headerBGC: 'rgba(0, 130, 196, 0.6)',
        oddRowBGC: 'rgba(0, 2, 92, 0.2)',
        evenRowBGC: 'rgba(0, 2, 92, 1)',
        data: []
      },
      Certificate: {}
    }
  },
  created () {
    this.getMarketRanking()
    this.getCertificate()
  },
  methods: {
    getMarketRanking () {
      getMarketRanking().then(res => {
        let datalist = res.data
        let arrlistresult = []
        datalist.forEach(item => {
          let arrlist = [
            item.ranking,
            item.market_information.comp_name,
            item.score
          ]
          arrlistresult.push(arrlist)
        })
        this.config = {
          header: ['排名', '市场名称', '分数'],
          data: arrlistresult,
          headerBGC: 'rgba(0, 130, 196, 0.6)',
          oddRowBGC: 'rgba(0, 2, 92, 0.2)',
          evenRowBGC: 'rgba(0, 2, 92, 1)',
          rowNum: 9,
          align: ['left', 'center', 'right']
        }
      })
    },
    getCertificate () {
      getCertificate().then(res => {
        this.Certificate = res.data
      })
    }
  },
  mounted () {}
}
</script>
<style lang="less" scoped>
.rankings {
  width: 100%;
  height: 100%;
  .top {
    width: 100%;
    height: 80%;
    border-bottom: 1px solid #033bf47a;
    padding: 0 0.1rem;
    box-sizing: border-box;
  }
  .bor {
    width: 0.02rem;
    height: 80%;
    background: #00fdfa;
    border-radius: 50%;
  }
  .bottom {
    width: 100%;
    height: 20%;
    font-size: 0.2rem;
    color: #00fdfa;
    .bottomNum:nth-child(2) {
      flex: 1;
    }
    .num {
      color: #fff;
      margin-top: 0.15rem;
    }
  }
  .bottomNum {
    flex: 0.5;
    text-align: center;
    font-size: 0.2rem;
    font-weight: bold;
  }
}
</style>

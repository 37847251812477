<template>
  <div class="footer flex" @click="classification">
    <div class="item flex" v-for="item in arr" :key="item.img" :id="item.id">
      <div :id="item.id">{{ item.name }}</div>
      <img :id="item.id" :src="item.img" alt="" />
      <div :id="item.id">{{ item.num }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footers',
  props: ['arr'],
  data () {
    return {}
  },
  created () {},
  methods: {
    classification (e) {
      this.$router.push({
        path: '/Classification',
        query: {
          index: e.target.id
        }
      })
    }
  },
  mounted () {}
}
</script>
<style lang="less" scoped>
.footer {
  width: 100%;
  height: 100%;
  cursor: pointer;
  .item {
    flex: 1;
    text-align: center;
    font-size: 0.16rem;
    color: #fff;
    flex-direction: column;
    height: 100%;
    padding: 0.1rem 0;
    box-sizing: border-box;
    img {
      width: 0.5rem;
      height: 0.5rem;
    }
  }
}
</style>

<template>
  <div
    class="bars"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="barEchart" ref="barEchart"></div>
  </div>
</template>

<script>
import { parameter, complaint, detectionBar, getDetectionBar } from '@api/index'

export default {
  // type = 1(市场进货台账) 2（市场统计快检）3（消费统计投诉统计）4(年初至和当前值)
  props: ['type'],
  name: 'bars',
  data () {
    let that = this
    this.options = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter (params) {
          if (that.type === 2) {
            let arr1 = params[0]
            let arr2 = params[3]
            return (
              arr1.axisValue +
              '<br />' +
              arr1.seriesName +
              ':' +
              arr1.value +
              '<br />' +
              arr2.seriesName +
              ':' +
              arr2.value
            )
          } else {
            return params[0].name + ':' + params[0].data
          }
        }
      },
      grid: {
        left: '2%',
        right: '2%',
        bottom: '2%',
        top: this.type === 2 || this.type === 4 ? '15%' : '8%',
        containLabel: true
      },
      legend: {
        show: this.type === 2 || this.type === 4,
        top: '2%',
        textStyle: {
          color: '#fff',
          fontSize: 14
        },
        selectedMode: false
      },
      xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
          textStyle: {
            color: '#fff', // x轴颜色
            fontSize: '10'
          }
          // rotate: 0
        }
      },
      yAxis: {
        axisLabel: {
          textStyle: {
            color: '#ffffff'
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: ['#0e2edc94'],
            width: 1,
            type: 'solid'
          }
        }
      },
      series: [
        {
          name: '快检批次',
          type: 'bar',
          barWidth: 15,
          itemStyle: {
            normal: {
              opacity: 0.7,
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: 'rgba(254, 147, 35)'
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(254, 147, 35, 0.5)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(254, 147, 35, 0.3)'
                  }
                ],
                false
              )
            }
          },
          data: []
        },
        {
          type: 'pictorialBar',
          symbolSize: [15, 6],
          symbolOffset: [0, -5],
          symbolPosition: 'end',
          z: 12,
          color: '#FFEA00',
          data: []
        },
        {
          type: 'pictorialBar',
          symbolSize: [15, 6],
          symbolOffset: [0, 1],
          z: 12,
          color: 'rgb(223, 238, 20,0.7)',
          data: []
        }
      ]
    }
    return {
      xAxis: [],
      series1: [],
      series2: [],
      loading: false
    }
  },
  created () {},
  methods: {
    echarts1 () {
      const MyEcharts = this.$echarts.init(this.$refs.barEchart)
      const options = { ...this.options }
      options.xAxis.data = this.xAxis
      options.series[0].data = this.series1
      options.series[1].data = this.series1
      options.series[2].data = this.series1
      if (this.type === 2 || this.type === 4) {
        options.series[1].symbolSize = [-15, 6]
        options.series[1].symbolOffset = [-9, -5]
        options.series[2].symbolSize = [15, 6]
        options.series[2].symbolOffset = [-9, -1]
        options.series[3] = {
          name: '合格批次',
          type: 'bar',
          barWidth: 15,
          itemStyle: {
            normal: {
              opacity: 0.7,
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: 'rgba(50, 169, 254,0.3)'
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(50, 169, 254, 0.5)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(50, 169, 254)'
                  }
                ],
                false
              ),
              barBorderRadius: 0
            }
          },
          data: this.series2
        }
        options.series[4] = {
          type: 'pictorialBar',
          symbolSize: [15, 6],
          symbolOffset: [9, -5],
          symbolPosition: 'end',
          z: 12,
          color: '#3440FF',
          data: this.series2
        }
        options.series[5] = {
          type: 'pictorialBar',
          symbolSize: [15, 6],
          symbolOffset: [9, 1],
          z: 12,
          color: 'rgba(126,192,238,0.6)',
          data: this.series2
        }
      }
      if (this.type === 4) {
        options.legend.left = '2%'
        options.series[0].name = '检测数量'
        options.series[3].name = '检测合格数'
        options.tooltip.formatter = params => {
          let str = `
          <div>${params[0].name}</div>
          <div>${params[0].seriesName}:${params[0].value}</div>
          <div>${params[3].seriesName}:${params[3].value}</div>
          `
          return str
        }
        options.dataZoom = [
          {
            type: 'inside',
            orient: '',
            start: 0,
            end: 50,
            preventDefaultMouseMove: true,
            moveOnMouseWheel: false,
            zoomLock: true
          }
        ]
      }
      MyEcharts.setOption(options, true)
      MyEcharts.resize()
    },
    // 进货台账 type=1（日）2（月）3（年）
    parameter (type = 1) {
      this.loading = true
      parameter({ type }).then(res => {
        let xAxis = []
        let series1 = []
        res.data.forEach(item => {
          xAxis.push(item.market_name)
          series1.push(item.count)
        })
        this.xAxis = xAxis
        this.series1 = series1
        this.echarts1()
        this.loading = false
      })
    },
    // 消费投诉统计 type=1（日）2（月）3（年）
    complaint (type = 1) {
      this.loading = true
      complaint({ type }).then(res => {
        const xAxis = []
        const series1 = []
        const complaintTotal = Object.keys(
          res.data.area_complaint_total
        ).reduce((a, b) => {
          return a + res.data.area_complaint_total[b]
        }, '')
        this.$parent.$parent.complaintTotal = complaintTotal
        res.data.barchart.forEach(item => {
          xAxis.push(item.comp_name)
          series1.push(item.market_complaint_total)
        })
        this.xAxis = xAxis
        this.series1 = series1
        this.echarts1()
        this.loading = false
      })
    },
    // 快检信息 type = 'day'（日） month（月） year（年）
    detectionBar (type = 'day') {
      detectionBar({ type }).then(res => {
        const xAxis = []
        const series1 = []
        const series2 = []
        res.data.forEach(item => {
          xAxis.push(item.comp_name)
          series1.push(item.detection_total_count)
          series2.push(item.detection_pass_count)
        })
        this.xAxis = xAxis
        this.series1 = series1
        this.series2 = series2
        this.echarts1()
        this.$parent.loading = false
      })
    },
    dayMonthYear (type) {
      if (this.type === 1) {
        type === 'day'
          ? this.parameter(1)
          : type === 'month'
          ? this.parameter(2)
          : this.parameter(3)
      }
      if (this.type === 3) {
        type === 'day'
          ? this.complaint(1)
          : type === 'month'
          ? this.complaint(2)
          : this.complaint(3)
      }
      if (this.type === 2) {
        this.detectionBar(type)
      }
    },
    getDetectionBar (params) {
      this.loading = true
      getDetectionBar(params).then(res => {
        const xAxis = []
        const series1 = []
        const series2 = []
        res.data.forEach(item => {
          xAxis.push(item.date)
          series1.push(item.detection_count) // 当前值
          series2.push(item.detection_pass_count) // 年初值
        })
        this.xAxis = xAxis
        this.series1 = series1
        this.series2 = series2
        this.echarts1()
        this.loading = false
      })
    }
  },
  mounted () {
    if (this.type === 1) {
      this.parameter()
    }
    if (this.type === 3) {
      this.complaint()
    }
    if (this.type === 2) {
      this.detectionBar()
    }
    if (this.type === 'type') {
      console.log(this.type)
    }
  }
}
</script>
<style lang="less" scoped>
.bars,
.barEchart {
  width: 100%;
  height: 100%;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/element'
// video.js
import videojs from 'video.js'
import 'videojs-contrib-hls'
import 'video.js/dist/video-js.css'
// echarts
import * as echarts from 'echarts'
import 'echarts-gl'
Vue.prototype.$echarts = echarts
// video.js
Vue.prototype.$videojs = videojs

Vue.config.productionTip = false

window._cancelToken = []

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <dv-border-box-10>
    <div class="map">
      <div class="radios flex">
        <!-- <div class="item">
          <img src="../../assets/radios.png" alt="" />
        </div> -->
        <div class="item flex">
          <div class="bor">计量设备{{ getTotalnum.device_count }}台</div>
          <div class="bor">农贸市场{{ getTotalnum.market_count }}家</div>
        </div>
        <div class="item flex">
          <div class="bor">异常{{ getTotalnum.abnormal_count }}台</div>
          <div class="bor">在线{{ getTotalnum.online_count }}台</div>
        </div>
        <div class="item flex">
          <div class="bor">市场经营户{{ getTotalnum.seller_count }}户</div>
          <div class="bor">已接入档铺{{ getTotalnum.join_seller_count }}个</div>
        </div>
      </div>
      <img class="img" src="../../assets/chunkBorder.png" alt="" />
      <div class="mapEchart" ref="mapEchart"></div>
    </div>
  </dv-border-box-10>
</template>

<script>
import { getMap, getTotal } from '@api/index'
export default {
  name: 'maps',
  data () {
    return {
      getTotalnum: {}
    }
  },
  created () {},
  methods: {
    echarts () {
      getMap().then(res => {
        const arr = []
        res.data.forEach(item => {
          if (!item.market_manage_personnels) {
            item.market_manage_personnels = {
              name: '无',
              tel: '无'
            }
          }
          arr.push({
            name: item.comp_name,
            value: [
              item.market_information_others.longitude * 1,
              item.market_information_others.latitude * 1,
              {
                tilte: item.comp_name,
                address: item.address,
                count: item.seller_count,
                joinSellerCount: item.join_seller_count,
                name: item.market_manage_personnels.name,
                tel: item.market_manage_personnels.tel,
                id: item.id
              }
            ]
          })
        })
        const option = {
          geo: [
            {
              map: '大鹏新区',
              aspectScale: 1,
              zoom: 0.63,
              layoutSize: '150%',
              layoutCenter: ['50%', '52%'],
              itemStyle: {
                areaColor: '#005DDC',
                borderColor: '#329BF5',
                borderWidth: 1
              },
              zlevel: -2,
              silent: true
            }
          ],
          // 提示框
          tooltip: {
            confine: true,
            triggerOn: 'click',
            className: 'areaTool',
            formatter: function (params) {
              if (!params.data) return
              //
              const data = params.data.value[2]
              return `<div onclick="tooltip(${JSON.stringify(data).replace(
                /"/g,
                '&quot;'
              )})"><div class="dot">市场名: ${
                data.tilte
              }</div><div class="dot">负责人: ${
                data.name
              }</div><div class="dot">经营户: ${
                data.count
              }户</div><div class="dot">已接入: ${
                data.joinSellerCount
              }户</div><div class="dot">联系电话: ${
                data.tel
              }</div><div class="dot dots">市场地址: ${
                data.address
              }</div></div>`
            },
            extraCssText:
              'width:200px; white-space:pre-wrap;color:#fff;border: 2px solid #0540f3;background: #03165094;'
          },
          series: [
            // 经纬度散点
            {
              type: 'effectScatter',
              coordinateSystem: 'geo',
              data: arr,
              showEffectOn: 'emphasis',
              rippleEffect: {
                brushType: 'stroke'
              },
              hoverAnimation: true,
              symbolSize: 10,
              label: {
                normal: {
                  formatter: '{b}',
                  position: 'top',
                  color: '#fff',
                  show: true
                },
                emphasis: {
                  show: false
                }
              },
              itemStyle: {
                normal: {
                  color: 'rgba(254, 147, 35)'
                }
              }
            },
            {
              name: '大鹏新区',
              type: 'map',
              coordinateSystem: 'geo',
              silent: true,
              map: '大鹏新区', // 自定义扩展图表类型 latitude: "22.578381"longitude: "113.88459"
              aspectScale: 1,
              zoom: 0.63, //缩放
              // 鼠标放上去后的字体样式
              emphasis: {
                show: false
              },
              itemStyle: {
                normal: {
                  areaColor: '#0160AD',
                  borderColor: '#37C1FD',
                  borderWidth: 2
                },
                emphasis: {
                  show: false,
                  borderWidth: 2,
                  borderColor: '#37C1FD',
                  areaColor: '#0160AD'
                }
              },
              layoutCenter: ['50%', '50%'], // 地图的缩放
              layoutSize: '150%',
              markPoint: {
                symbol: 'none'
              }
            }
          ]
        }
        this.myEcharts.setOption(option, true)
        this.myEcharts.resize()
      })
    },
    tooltip (data) {
      this.$router.push({
        path: '/about',
        query: data
      })
    },
    getTotal () {
      getTotal().then(res => {
        this.getTotalnum = res.data
      })
    }
  },
  mounted () {
    this.$echarts.registerMap('大鹏新区', require('../../utils/440300.json'))
    this.myEcharts = this.$echarts.init(this.$refs.mapEchart)
    this.echarts()
    this.getTotal()
    window.tooltip = this.tooltip
  }
}
</script>
<style lang="less" scoped>
/deep/ .border-box-content {
  padding: 2px;
  box-sizing: border-box;
}
/deep/ .areaTool {
  pointer-events: auto !important;
}
/deep/ .dot {
  position: relative;
  padding-left: 0.15rem;
  width: 100%;
}
/deep/ .dot::before {
  content: '';
  display: block;
  width: 0.1rem;
  height: 0.1rem;
  background: #0097fc;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
/deep/ .dots::before {
  top: 28%;
}

.map {
  width: 100%;
  height: 100%;
  border-radius: 0.1rem;
  background: #091136;
  background-image: url('../../assets/map.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .radios {
    width: 100%;
    height: 1.1rem;
    font-size: 0.2rem;
    padding: 0.1rem 0.3rem 0;
    box-sizing: border-box;

    .item {
      height: 100%;
      flex: 1;
      text-align: center;
      flex-direction: column;
      color: #00fbfc;
    }
    img {
      width: 1rem;
      height: 100%;
    }
    .bor {
      border: #00fbfc 1px solid;
      padding: 0.02rem 0.1rem;
      box-sizing: border-box;
      min-width: 1.5rem;
      border-radius: 0.04rem;
    }
  }
  .flex {
    justify-content: space-around;
  }
  .mapEchart {
    width: 100%;
    height: calc(100% - 1.1rem);
  }
  .img {
    width: 60%;
    height: 00.1rem;
    margin: 0 auto;
    position: absolute;
    top: 1.15rem;
    left: 20%;
  }
}
</style>

<template>
  <div class="home flex">
    <!-- 左边 -->
    <div class="left flex">
      <div class="left55 flex">
        <div class="ranking" style="height:100%">
          <border name="市场排名">
            <ranking />
          </border>
        </div>
        <!-- <div class="pie" style="height:44%">
          <border name="市场商品类目热销笔数比例">
            <pie type="home" />
          </border>
        </div> -->
      </div>
      <div class="left45 flex">
        <div class="ranking">
          <border name="市场商品类目热销笔数比例">
            <pie type="home" />
          </border>
          <!-- <border name="市场进货台账">
            <bar :type="1" />
          </border> -->
        </div>
        <div class="pie">
          <border name="市场配套设施">
            <footers :arr="arr1" />
          </border>
        </div>
      </div>
    </div>
    <!-- 中间 -->
    <div class="center flex">
      <div class="map">
        <maps />
      </div>
      <div class="line">
        <border>
          <lines type="home" />
        </border>
      </div>
    </div>
    <!-- 右边 -->
    <div class="right flex">
      <div class="left55">
        <border name="市场快检统计">
          <chunk />
        </border>
      </div>
      <div class="left45 flex">
        <div class="ranking">
          <border name="消费统计投诉统计">
            <bar :type="3" />
          </border>
        </div>
        <div class="pie">
          <border name="市场配套设施">
            <footers :arr="arr2" />
          </border>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import border from '@comp/home/border.vue'
import ranking from '@comp/home/ranking.vue'
import pie from '@comp/home/pie.vue'
import bar from '@comp/home/bar.vue'
import footers from '@comp/home/footer.vue'
import lines from '@comp/home/line.vue'
import maps from '@comp/home/map.vue'
import chunk from '@comp/home/chunk.vue'
import { supportingFacility } from '@api/index'
export default {
  name: 'Home',
  components: {
    border,
    ranking,
    pie,
    bar,
    footers,
    lines,
    maps,
    chunk
  },
  data () {
    return {
      arr1: [
        {
          name: '公厕的数量',
          img: require('@assets/homeFooter1.png'),
          num: 0,
          id: 0
        },
        {
          name: '母婴室数量',
          img: require('@assets/homeFooter2.png'),
          num: 0,
          id: 1
        },
        {
          name: '食品安全员工作室',
          img: require('@assets/homeFooter3.png'),
          num: 0,
          id: 2
        },
        {
          name: '垃圾收集分类点',
          img: require('@assets/homeFooter4.png'),
          num: 0,
          id: 3
        },
        {
          name: '志愿者服务站',
          img: require('@assets/homeFooter5.png'),
          num: 0,
          id: 4
        },
        {
          name: '智慧农贸工作室',
          img: require('@assets/homeFooter6.png'),
          num: 0,
          id: 5
        },
        {
          name: '消费者维权投诉站',
          img: require('@assets/homeFooter7.png'),
          num: 0,
          id: 6
        }
      ],
      arr2: [
        {
          name: '公平秤',
          img: require('@assets/homeFooter8.png'),
          num: 0,
          id: 7
        },
        {
          name: '广播设备',
          img: require('@assets/homeFooter9.png'),
          num: 0,
          id: 8
        },
        {
          name: '监控设备',
          img: require('@assets/homeFooter10.png'),
          num: 0,
          id: 9
        },
        {
          name: '停车位',
          img: require('@assets/homeFooter11.png'),
          num: 0,
          id: 10
        },
        {
          name: '消防点',
          img: require('@assets/homeFooter12.png'),
          num: 0,
          id: 11
        },
        {
          name: '烟感设备',
          img: require('@assets/homeFooter13.png'),
          num: 0,
          id: 12
        },
        {
          name: '消防队',
          img: require('@assets/homeFooter14.png'),
          num: 0,
          id: 13
        }
      ]
    }
  },
  created () {
    this.supporting_Facility()
  },
  provide () {
    return {
      button: this.borderClick
    }
  },
  methods: {
    // 市场配套设施
    supporting_Facility () {
      supportingFacility().then(res => {
        this.arr1[0].num = res.data.public_toilets_count + '间'
        this.arr1[1].num = res.data.child_room_count + '间'
        this.arr1[2].num = res.data.food_safety_count + '间'
        this.arr1[3].num = res.data.waste_count + '处'
        this.arr1[4].num = res.data.volunteer_count + '处'
        this.arr1[5].num = res.data.wisdom_count + '间'
        this.arr1[6].num = res.data.customer_count + '处'
        this.arr2[0].num = res.data.check_scale_count + '处'
        this.arr2[1].num = res.data.broadcasting_equipment_count + '处'
        this.arr2[2].num = res.data.monitoring_equipment_count + '处'
        this.arr2[3].num = res.data.parking_spaces_count + '处'
        this.arr2[4].num = res.data.firehouse_count + '处'
        this.arr2[5].num = res.data.smoke_detector_count + '处'
        this.arr2[6].num = res.data.fire_company_count + '处'
      })
    },
    borderClick (val) {
      console.log('HOME', val)
    }
  },
  mounted () {}
}
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  height: calc(100% - 0.7rem);
  padding: 0.2rem;
  box-sizing: border-box;
  overflow: hidden;
  .left,
  .right {
    width: 27.5%;
    height: 100%;
    flex-direction: column;
    .left55 {
      width: 100%;
      height: 54%;
      flex-direction: column;
    }
    .left45 {
      width: 100%;
      height: 44%;
      flex-direction: column;
    }
    .ranking {
      width: 100%;
      height: 48%;
      // border: 1px solid red;
    }
    .pie {
      width: 100%;
      height: 48%;
      // border: 1px solid red;
    }
  }
  .center {
    width: 43%;
    height: 100%;
    flex-direction: column;
    .map {
      width: 100%;
      height: 64%;
      // border: 1px solid red;
    }
    .line {
      width: 100%;
      height: 34%;
    }
  }
}
</style>

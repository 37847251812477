import axios from "axios";
import router from "@router";
import store from "@store";
import { Message } from "element-ui";

//  请求失败后的错误统一处理
const errorHandle = (status, res) => {
  // 状态码判断
  switch (status) {
    // 401: 未登录状态，跳转登录页
    case 401:
      tip("请登录后在执行下一步操作");
      toLogin();
      break;
    // 403 token过期 清除token并跳转登录页
    case 403:
      tip("登录过期,请重新登录");
      localStorage.removeItem("token");
      break;
    // 404请求不存在
    case 404:
      tip("请求的资源不存在");
      break;
    case 500:
      tip("服务器报错");
      break;
    case 502:
      tip("服务器报错");
      break;
    default:
      return Promise.reject(res);
  }
};

// 提示
const tip = (message) => {
  Message({
    message,
    type: "error",
    customClass: "message",
  });
};

//跳转登录页
const toLogin = () => {
  router.replace({
    path: "/login",
    query: {
      redirect: router.currentRoute.fullPath,
    },
  });
};

// axios 配置
var instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30000, //超时30秒自动中断请求
  baseURL: "https://farmers.hengshunits.com", //接口请求地址
});

//拦截重复请求
const pending = []; //声明一个数组用于存储每个ajax请求的取消函数和ajax标识
const cancelToken = axios.CancelToken;

const removeRepeatUrl = (config) => {
  for (const p in pending) {
    if (
      pending[p].u ===
      config.url + "&" + config.method + "&" + JSON.stringify(config.params)
    ) {
      // 当前请求在数组中存在时执行函数体
      pending[p].f(); //执行取消操作
      pending.splice(p, 1); //把这条记录从数组中移除
      // 全局添加取消的数组方便路由跳转时取消剩余的请求
      window._cancelToken = pending;
    }
  }
};

const saveRepeatUrl = (config) => {
  config.cancelToken = new cancelToken((c) => {
    pending.push({
      u: config.url + "&" + config.method + "&" + JSON.stringify(config.params),
      f: c,
    }); // 自定义唯一标识
    // 全局添加取消的数组方便路由跳转时取消剩余的请求
    window._cancelToken = pending;
  });
};

// 添加请求拦截器
instance.interceptors.request.use((config) => {
  config.headers["X-Requested-With"] = "XMLHttpRequest";
  const token = store.state.userInfo.access_token;
  token && (config.headers.Authorization = "Bearer " + token);
  // 在发送请求之前做些什么，比如传token
  removeRepeatUrl(config); // 在一个ajax发送前执行一下取消操作
  saveRepeatUrl(config); // 保存请求地址
  return config;
});

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    removeRepeatUrl(response.config);
    const res = response.data;
    // res.code后端返回的状态码
    if (res.code >= 200 && res.code < 300) {
      return Promise.resolve(res);
    } else {
      return errorHandle(res.code, res);
    }
  },
  (error) => {
    if (error) {
      return errorHandle(error.code, error);
    }
  }
);

export default instance;
